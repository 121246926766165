import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';


export enum MessageType {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  INFO = 'INFO'
};

@Injectable({
  providedIn: 'root'
})
export class AppMessageService {

  constructor(
    private translateService: TranslateService,
    private messageService: MessageService
  ) { }

  public infoMessage(res: any) {
    this.messageService.add({
      severity: 'info',
      summary: this.translateService.instant( 'message-service.info' ),
      detail: res?.message ? this.translateService.instant(res.message) : null,
    });
  }
  public successMessage(res: any, customSummary?: string, translationData?: { summary?: object, detail?: object }, otherSettings?: { life?: number; }) {
    if (res.noToast) return;

    if (res.messageType === MessageType.INFO) {
      this.infoMessage(res);
    } else {
      this.messageService.add({
        severity: 'success',
        summary: this.translateService.instant( customSummary || 'message-service.success', translationData?.summary ),
        detail: res?.message ? this.translateService.instant(res.message, translationData?.detail) : null,
        life: otherSettings?.life
      });
    }
  }
  public errorMessage(err: any, customSummary?: string, translationData?: { summary?: object, detail?: object }, otherSettings?: { life?: number; }) {
    this.messageService.add({
      severity: 'error',
      summary: this.translateService.instant( customSummary || 'message-service.error', translationData?.summary ),
      detail: err?.message ? this.translateService.instant(err.message, translationData?.detail) : null,
      life: otherSettings?.life
    });
  }
}
